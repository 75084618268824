
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'
import CustomDialog from '@/components/custom-dialog/custom-dialog.vue'
import { httpRequest } from '@/plugins'

@Options({
  components: {
    PagePlugin,
    CustomDialog
  }
})
export default class change_manage extends SuperVue {
  validateOpenTime(rule, value, callback) {
    if (value < 15) {
      callback(new Error('公示时间不能少于15天'))
    } else {
      callback()
    }
  }
  buttonShow(name) {
    return Utils.showBtn(name)
  }
  changes_all_data = []
  changes_total = 0
  changesTb = {
    page: 1,
    pageSize: 10
  }
  changes_search_data = {
    start_time: '',
    end_time: '',
    user_name: '',
    name: ''
  }
  rule_changes_search_data = {}
  changes_detail_dialog = {
    open: false,
    title: '详情'
  }
  changes_detail_data = {
    code: '',
    name: '',
    party_a: '',
    party_b: '',
    party_a_proportion: 0,
    party_b_proportion: 0,
    sum: 0,
    assignments: [] as any[],
    type: 0,
    materials: []
  }
  changes_edit_dialog = {
    open: false,
    title: '部门审核',
    res_change_id: 0,
    status: 2,
    reason: ''
  }
  changes_edit_dialog1 = {
    open: false,
    title: '指派',
    current_status: 0
  }
  changes_edit_data = {
    id: 0,
    users: [],
    userList: [],
    deptList: [],
    sign_type: '',
    duration: 0,
    public_time: 0
  }

  rule_changes_edit_data = {
    users: [
      {
        required: true,
        type: 'array',
        message: '此项不能为空',
        trigger: 'blur'
      }
    ],
    sign_type: [{ required: true, message: '此项不能为空', trigger: 'blur' }]
  }
  userList: any[] = []
  statusShow = new Map<any, string>([[3, '待指派部门审核员']])
  audit_status_234 = new Map<any, string>([
    [0, '草稿'],
    [1, '待成员审核'],
    [2, '审核不通过'],
    [3, '待指派部门审核员'],
    [4, '待部门审核'],
    [5, '待指派产研院专员'],
    [6, '待产研院审核（转让许可）'],
    [7, '待指派权属委专员'],
    [8, '待权属委审核（转让许可）'],
    [9, '待指派产研院公示审核员'],
    [10, '待公示完成'],
    [11, '待指派校章专员'],
    [12, '待盖章'],
    [13, '已通过'],
    [14, '待指派资产公司专员'],
    [15, '待资产公司审核（作价投资）'],
    [16, '待指派权属委专员'],
    [17, '待权属委审核（作价投资）'],
    [18, '待指派资产公司专员'],
    [19, '待指派异常审议人'],
    [20, '待异常审议'],
    [21, '待指派异常复审人'],
    [22, '待异常审议复审']
  ])
  type_246 = new Map<any, string>([
    [1, '转让许可'],
    [2, '作价投资']
  ])
  sign_type_249 = new Map<any, string>([
    [2, '并签'],
    [1, '或签']
  ])
  pageChange({ currentPage, pageSize }) {
    this.changesTb.page = currentPage
    this.changesTb.pageSize = pageSize
  }
  changes_get_all_data(offset = 0, length = 10) {
    const audit_status = [] as number[]
    for (let i = 1; i < 23; i++) audit_status.push(i)
    const postData = {
      offset: offset || 0,
      length: length || 10,
      user_name: this.changes_search_data.user_name,
      name: this.changes_search_data.name,
      audit_status,
      dept_id: this.$store.state.userInfo.dept_id,
      type: null
    }
    Api.http_change_managechanges0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.changes_all_data = res.data.data
        this.changes_total = res.data.total
      }
    })
  }
  changes_detail_init(row) {
    Api.http_change_managechangesget4({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.changes_detail_data.code = res.data.code
        this.changes_detail_data.name = res.data.name
        this.changes_detail_data.party_a = res.data.party_a
        this.changes_detail_data.party_b = res.data.party_b
        this.changes_detail_data.party_a_proportion
          = res.data.party_a_proportion
        this.changes_detail_data.party_b_proportion
          = res.data.party_b_proportion
        this.changes_detail_data.sum = res.data.sum
        this.changes_detail_data.assignments = res.data.assignments
        this.changes_detail_data.type = res.data.type
        this.changes_detail_data.materials = res.data.materials
        this.changes_detail_dialog.open = true
        this.setDialogName(this.changes_detail_dialog.title)
      }
    })
  }
  changes_detail_cancle() {
    this.changes_detail_dialog.open = false
    this.removeDialogName()
  }
  changes_detail_ok() {
    this.changes_detail_dialog.open = false
    this.removeDialogName()
  }
  changes_edit_init(row) {
    this.changes_edit_dialog.res_change_id = row.id
    this.changes_edit_dialog.open = true
  }
  changes_edit1_init(row) {
    this.get_userList(row.id)
    this.changes_edit_dialog1.current_status = row.audit_status

    Api.http_change_managechangesget5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.changes_edit_data.id = row.id
        this.changes_edit_data.users = res.data.users
        this.changes_edit_data.sign_type = ''
        this.changes_edit_data.duration = res.data.duration
        this.getDeptsByStatus(row.id)
      }
    })
  }
  getDeptsByStatus(id: number) {
    Api.http_change_managechangesuserDepts({ id: id }).then((res) => {
      if (res.code === '200') {
        this.changes_edit_data.deptList = res.data.data
        this.changes_edit_dialog1.open = true
      } else {
        this.$message.error(res.message)
      }
    })
  }
  get_userList(id = -1) {
    Api.http_change_managechangesuserList0({
      id: id,
      offset: 0,
      length: 999
    }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.userList = res.data.data
      }
    })
  }
  changes_edit_cancle() {
    this.changes_edit_dialog.open = false
  }
  changes_edit_ok() {
    const { res_change_id, status, reason } = this.changes_edit_dialog
    if (status === 3 && reason === '') {
      return this.$message.error('审核意见不能为空')
    }
    httpRequest
      .post('/api/v1/change_audit/change/deptAudit', {
        res_change_id,
        status,
        reason
      })
      .then((res: any) => {
        if (res.code !== '200') return this.$message.error(res.message)
        else {
          this.$message.success(res.message)
          this.changes_edit_dialog.open = false
          this.changes_get_all_data((this.changesTb.page - 1) * this.changesTb.pageSize, this.changesTb.pageSize)
        }
      })
  }
  changes_edit1_cancle() {
    this.changes_edit_dialog1.open = false
  }
  changes_edit1_ok() {
    this.$refs.ref_changes_edit_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.changes_edit_data.id,
          users: this.changes_edit_data.users,
          sign_type: this.changes_edit_data.sign_type,
          duration: this.changes_edit_data.duration
        } as any

        if (
          this.changes_edit_dialog1.current_status === 9
          || this.changes_edit_dialog1.current_status === 18
        ) {
          postData.public_time = this.changes_edit_data.public_time
        }

        Api.http_change_managechanges5(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.changes_edit_dialog1.open = false
            this.changes_get_all_data(0, 10)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  changes_init() {
    this.changes_get_all_data(0, 10)
  }
  created() {
    this.changes_init()
  }
}
